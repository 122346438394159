.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.spinner {
  font-size: 1.5rem;
}

.spinner > * {
  font-size: 3.5rem;
  animation: spinner 3s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
