h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

.strong {
  font-weight: 800;
}

.container {
  width: 80%;
  margin: 1.5rem auto;
  padding: 1.5rem;
  min-height: 100%;
  text-align: justify;
}

.two-card-layout {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.text-tertiary {
  color: var(--tertiary) !important;
}

.page-header {
  font-size: 4rem !important;
  text-align: center;
}

.container h3 {
  font-size: 2rem;
}

.container > p {
  text-align: justify;
}

.sub-container {
  padding: 1rem;
}

.container ul li {
  margin: 1rem 0;
}

.text-center {
  text-align: center !important;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 1.5rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .sub-container {
    margin-bottom: 2rem;
    padding: 0rem;
  }

  h1 {
    font-size: 3rem !important;
  }
  .page-header {
    font-size: 2.3rem !important;
  }
  .two-card-layout {
    margin: 1rem 1rem 2rem;
    display: block;
  }
}
